import { createDarkTheme, FluentProvider, makeStyles, Subtitle1, Tab, TabList } from '@fluentui/react-components';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Header } from '@zastrpay/layout';
import { breakpoints, tokens } from '@zastrpay/theme';

import { pageWidth } from '../config';

const darkTheme = createDarkTheme({
    10: '#ffffff',
    20: '#ffffff',
    30: '#ffffff',
    40: '#ffffff',
    50: '#ffffff',
    60: '#ffffff',
    70: '#ffffff',
    80: '#ffffff',
    90: '#ffffff',
    100: '#ffffff',
    110: '#ffffff',
    120: '#ffffff',
    130: '#ffffff',
    140: '#ffffff',
    150: '#ffffff',
    160: '#ffffff',
});

darkTheme.colorNeutralForeground2 = 'rgba(255, 255, 255, 0.75)';
darkTheme.colorNeutralForeground2Hover = 'rgba(255, 255, 255, 0.9)';

darkTheme.colorNeutralStroke1 = 'rgba(255, 255, 255, 0.35)';
darkTheme.colorNeutralStroke1Hover = 'rgba(255, 255, 255, 0.5)';
darkTheme.colorNeutralStroke1Pressed = 'rgba(255, 255, 255, 0.5)';

export const Layout: React.FC = () => {
    const classes = useStyles();
    const isMinSmallDevice = useMediaQuery(`(min-width: ${breakpoints.small})`);

    const navigate = useNavigate();
    const location = useLocation();

    const selectedLocation = location.pathname
        .split('/')
        .filter((segment) => !!segment)
        .shift();

    const changeLocation = (target: string | unknown) => navigate(`/${target}`);

    return (
        <>
            <Header className={classes.header} />
            <FluentProvider theme={darkTheme} className={classes.wrapper}>
                <div className={classes.container}>
                    <Subtitle1 className={classes.title}>Merchant App</Subtitle1>
                    <TabList
                        size={isMinSmallDevice ? 'large' : 'small'}
                        selectedValue={selectedLocation ?? ''}
                        onTabSelect={(_, data) => changeLocation(data.value)}
                    >
                        <Tab value="redirect-sessions">Redirect Sessions</Tab>
                        <Tab value="generate-code">Generate QR</Tab>
                    </TabList>
                </div>
            </FluentProvider>
            <Outlet />
        </>
    );
};

const useStyles = makeStyles({
    header: {
        position: 'sticky',
        inset: '0 0 auto',
        zIndex: 1,
        paddingTop: tokens.customHeaderPadding,
    },
    wrapper: {
        backgroundColor: tokens.customPaletteGreen,
        color: tokens.colorNeutralForegroundStaticInverted,
        padding: `1px ${tokens.spacingHorizontalM}`,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: `calc(${pageWidth}px - 2 * ${tokens.spacingHorizontalXXL})`,
        margin: 'auto',
        gap: `0 ${tokens.spacingHorizontalL}`,
        [`@media (min-width: ${breakpoints.small})`]: {
            flexDirection: 'row',
        },
    },
    title: {
        padding: `${tokens.spacingVerticalXS} 0`,
    },
});
