import { useFormContext } from 'react-hook-form';

import { register } from '@zastrpay/common';
import { InputField } from '@zastrpay/components';

import { Form } from './Form';
import { CreateExistingTransactionIntentRedirectSession } from './models';

export const ExistingTransactionIntentRedirectSessionForm: React.FC = () => {
    const { control } = useFormContext<CreateExistingTransactionIntentRedirectSession>();

    return (
        <Form>
            <div>
                <InputField
                    {...register(control, 'transactionIntentId', { required: 'Transaction Intent Id is required' })}
                    label="Transaction Intent Id"
                />
            </div>
            <div>
                <InputField {...register(control, 'customerId', { required: 'Customer Id is required' })} label="Customer Id" />
            </div>
        </Form>
    );
};
