import { CheckmarkCircleRegular, DismissCircleRegular, ErrorCircleRegular, QrCodeRegular } from '@fluentui/react-icons';
import { Route, Routes } from 'react-router-dom';

import { RedirectSessionForm } from './RedirectSessionForm';
import { RedirectSessionResult } from './RedirectSessionResult';

export const RedirectSessionRouting: React.FC = () => {
    return (
        <Routes>
            <Route index element={<RedirectSessionForm />} />
            <Route
                path="abort"
                element={
                    <RedirectSessionResult icon={<DismissCircleRegular />} title="Redirect session operation was aborted by customer" />
                }
            />
            <Route
                path="failure"
                element={<RedirectSessionResult icon={<ErrorCircleRegular />} title="Redirect session operation failed" />}
            />
            <Route
                path="success"
                element={
                    <RedirectSessionResult icon={<CheckmarkCircleRegular />} title="Redirect session operation was completed by customer" />
                }
            />
            <Route
                path="code-success"
                element={<RedirectSessionResult icon={<QrCodeRegular />} title="Transaction intent was created by customer" />}
            />
        </Routes>
    );
};
