import { Checkbox, makeStyles, Tab, TabList } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { pick, register } from '@zastrpay/common';
import { Button, InputField, SelectField } from '@zastrpay/components';
import { redirect } from '@zastrpay/redirect-session';
import { breakpoints } from '@zastrpay/theme';

import { domain } from '../config';
import { Page } from '../layout/Page';
import { create } from './api';
import { ExistingTransactionIntentRedirectSessionForm } from './ExistingTransactionIntentRedirectSessionForm';
import { Form } from './Form';
import { CreateRedirectSession, RedirectSessionType } from './models';
import { NewCustomerRedirectSessionForm } from './NewCustomerRedirectSessionForm';
import { NewTransactionIntentRedirectSessionForm } from './NewTransactionIntentRedirectSessionForm';
import { RedirectDataForm } from './RedirectDataForm';

export type RedirectSessionData = { apiKey: string; store: boolean } & CreateRedirectSession;

type DefaultValues = { apiKey?: string; locale?: string; customerId?: string };

const getDefaultValues = (): DefaultValues | undefined => {
    const defaultValues = JSON.parse(localStorage.getItem('defaultValues') || 'null');

    if (defaultValues) {
        return {
            apiKey: defaultValues.apiKey,
            locale: defaultValues.locale,
            customerId: defaultValues.customerId,
        };
    }
};

export const setDefaultValues = (apiKey?: string, locale?: string, customerId?: string) => {
    if (apiKey || locale || customerId) {
        localStorage.setItem('defaultValues', JSON.stringify({ apiKey, locale, customerId }));
    }
};

export const removeSettings = () => {
    localStorage.removeItem('defaultValues');
};

export const defaultValues = getDefaultValues();

export const RedirectSessionForm: React.FC = () => {
    const classes = useStyles();

    const isMinSmallDevice = useMediaQuery(`(min-width: ${breakpoints.small})`);

    const form = useForm<RedirectSessionData>({
        mode: 'onChange',
        defaultValues: {
            ...defaultValues,
            store: !!defaultValues,
            type: 'NewTransactionIntent',
            transactionData: {
                currency: 'EUR',
            },
            redirectData: {
                abortUrl: `${domain}/redirect-sessions/abort`,
                failureUrl: `${domain}/redirect-sessions/failure`,
                successUrl: `${domain}/redirect-sessions/success`,
                qrCodeSuccessUrl: `${domain}/redirect-sessions/code-success`,
            },
        },
    });

    const { formState, control, watch, handleSubmit, setValue, getValues, resetField } = form;

    const [error, setError] = useState<string>();

    const type = watch('type');
    const typeChanged = (value: RedirectSessionType) => {
        setValue('type', value);

        const fields = getValues();
        const commonFields = ['apiKey', 'locale', 'redirectData', 'store', 'type'];

        for (const field in fields) {
            if (!commonFields.includes(field)) {
                resetField(field as keyof RedirectSessionData);
            }
        }
    };

    const submit = handleSubmit(({ apiKey, store, ...session }) => {
        if (store) {
            const customerId = session.type === 'NewTransactionIntent' ? session.customerId : undefined;

            setDefaultValues(apiKey, session.locale, customerId);
        } else {
            removeSettings();
        }

        const id = uuid();

        switch (session.type) {
            case 'NewTransactionIntent':
                session = pick(session, 'type', 'locale', 'redirectData', 'transactionData', 'customerId', 'phoneNumber');
                session.transactionData.amount = Number(session.transactionData.amount);
                break;
            case 'ExistingTransactionIntent':
                session = pick(session, 'type', 'locale', 'redirectData', 'customerId', 'transactionIntentId');
                break;
            case 'NewCustomer':
                session = pick(session, 'type', 'locale', 'redirectData', 'customerData');
                break;
        }

        create(id, apiKey, session)
            .then(({ redirectUrl, token }) => redirect({ redirectUrl, token }))
            .catch((error) => setError(error.message));
    });

    return (
        <Page>
            <FormProvider {...form}>
                <Form title="Settings">
                    <div>
                        <InputField
                            {...register(control, 'apiKey', { required: 'Api key is required for authenticating the service call' })}
                            label="Api key"
                        />
                    </div>
                    <div>
                        <SelectField
                            label="Language"
                            {...register(control, 'locale')}
                            options={[
                                { key: 'none', text: '-' },
                                { key: 'en', text: 'English', value: 'en-GB' },
                                { key: 'de', text: 'Deutsch', value: 'de-DE' },
                            ]}
                        />
                    </div>
                    <div className={classes.settings}>
                        <Controller
                            defaultValue={!!formState.defaultValues?.store}
                            name="store"
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    checked={field.value}
                                    onBlur={field.onBlur}
                                    onChange={field.onChange}
                                    label="Store above settings in your browser?"
                                    className={classes.settingsCheck}
                                />
                            )}
                        />
                    </div>
                </Form>

                <TabList
                    className={classes.tabs}
                    selectedValue={type}
                    vertical={!isMinSmallDevice}
                    onTabSelect={(_, data) => typeChanged(data.value as RedirectSessionType)}
                >
                    <Tab value="NewTransactionIntent">New Transaction Intent</Tab>
                    <Tab value="ExistingTransactionIntent">Existing Transaction Intent</Tab>
                    <Tab value="NewCustomer">New Customer</Tab>
                </TabList>

                {type === 'NewTransactionIntent' && <NewTransactionIntentRedirectSessionForm />}
                {type === 'ExistingTransactionIntent' && <ExistingTransactionIntentRedirectSessionForm />}
                {type === 'NewCustomer' && <NewCustomerRedirectSessionForm />}

                {/* Use key to rerender on tab change */}
                <RedirectDataForm key={`redirect-data-${type}`} />

                {error && <Alert intent="error">{error} </Alert>}

                <div className={classes.actions}>
                    <Button appearance="primary" onClick={submit}>
                        Create
                    </Button>
                </div>
            </FormProvider>
        </Page>
    );
};

export const useStyles = makeStyles({
    actions: {
        display: 'flex',
        gap: '16px',
        justifyContent: 'end',
    },
    settings: {
        gridColumnEnd: 'span 2',
    },
    settingsCheck: {
        marginLeft: 'calc(var(--spacingHorizontalS) * -1.0)',
    },
    tabs: {
        margin: '0 -12px',
    },
});
