import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LayoutProvider } from '@zastrpay/layout';
import { ThemeProvider } from '@zastrpay/theme';

import { App } from './App';

const container = document.getElementById('root');

if (!container) {
    throw new Error('Container should be defined');
}

const root = createRoot(container);

root.render(
    // <React.StrictMode> // it causes problem because in strict mode all Effects are re-run an extra time.
    <BrowserRouter>
        <ThemeProvider>
            <LayoutProvider>
                <App />
            </LayoutProvider>
        </ThemeProvider>
    </BrowserRouter>,
    // </React.StrictMode>,
);
