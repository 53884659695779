import { useFormContext } from 'react-hook-form';

import { register } from '@zastrpay/common';
import { InputField, SelectField } from '@zastrpay/components';

import { Form } from './Form';
import { CreateNewTransactionIntentRedirectSession } from './models';

export const NewTransactionIntentRedirectSessionForm: React.FC = () => {
    const { control } = useFormContext<CreateNewTransactionIntentRedirectSession>();

    return (
        <Form>
            <div>
                <InputField
                    {...register(control, 'transactionData.amount', { required: 'Amount is required' })}
                    label="Amount"
                    type="number"
                    inputMode="decimal"
                />
            </div>
            <div>
                <SelectField
                    {...register(control, 'transactionData.currency', { required: 'Transaction currency is required' })}
                    label="Currency"
                    options={[{ key: 'EUR', text: 'Euro', value: 'EUR' }]}
                />
            </div>
            <div>
                <SelectField
                    {...register(control, 'transactionData.type')}
                    label="Type"
                    options={[
                        { key: 'none', text: '-' },
                        { key: 'deposit', text: 'Deposit', value: 'PassthroughDeposit' },
                        { key: 'withdrawal', text: 'Withdrawal', value: 'PassthroughWithdrawal' },
                        { key: 'merchantToCustomerTransfer', text: 'Merchant To Customer Transfer', value: 'MerchantToCustomerTransfer' },
                        {
                            key: 'customerToMerchantPassthrough',
                            text: 'Customer To Merchant Passthrough',
                            value: 'CustomerToMerchantPassthrough',
                        },
                        {
                            key: 'merchantToCustomerPassthrough',
                            text: 'Merchant To Customer Passthrough',
                            value: 'MerchantToCustomerPassthrough',
                        },
                    ]}
                />
            </div>
            <div>
                <SelectField
                    {...register(control, 'transactionData.direction')}
                    label="Direction"
                    options={[
                        { key: 'none', text: '-' },
                        { key: 'customerToMerchant', text: 'Customer To Merchant', value: 'CustomerToMerchant' },
                        { key: 'merchantToCustomer', text: 'Merchant To Customer', value: 'MerchantToCustomer' },
                    ]}
                />
            </div>
            <div>
                <InputField {...register(control, 'transactionData.externalReference')} label="External reference" />
            </div>
            <div>
                <InputField {...register(control, 'customerId')} label="Customer Id" />
            </div>
            <div>
                <InputField {...register(control, 'phoneNumber')} label="Phone number" type="tel" />
            </div>
        </Form>
    );
};
