import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { useFormContext } from 'react-hook-form';

import { register } from '@zastrpay/common';
import { InputField, SelectField } from '@zastrpay/components';
import { breakpoints, tokens } from '@zastrpay/theme';

import { countries } from '../config';
import { Form } from './Form';
import { CreateNewCustomerRedirectSession } from './models';

export const NewCustomerRedirectSessionForm: React.FC = () => {
    const classes = useStyles();
    const { control } = useFormContext<CreateNewCustomerRedirectSession>();

    const countryOptions = [
        { key: 'none', text: '-', value: undefined },
        ...countries.map((c) => ({ key: c.code, text: c.name, value: c.code })),
    ];

    return (
        <Form>
            <div>
                <InputField {...register(control, 'customerData.firstName')} label="First name" />
            </div>
            <div>
                <InputField {...register(control, 'customerData.lastName')} label="Last name" />
            </div>
            <div>
                <InputField {...register(control, 'customerData.middleName')} label="Middle name" />
            </div>
            <div>
                <InputField {...register(control, 'customerData.maidenName')} label="Maiden name" />
            </div>
            <div>
                <SelectField {...register(control, 'customerData.nationality')} label="Nationality" options={countryOptions} />
            </div>
            <div>
                <InputField
                    {...register(control, 'customerData.dateOfBirth')}
                    label="Date of birth"
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                />
            </div>
            <div>
                <InputField {...register(control, 'customerData.placeOfBirth')} label="Place of birth" />
            </div>
            <div>
                <SelectField {...register(control, 'customerData.countryOfBirth')} label="Country of birth" options={countryOptions} />
            </div>
            <div className={classes.section}>
                <Subtitle1>Contact</Subtitle1>
            </div>
            <div>
                <InputField {...register(control, 'customerData.contact.email')} type="email" label="Email" />
            </div>
            <div>
                <InputField {...register(control, 'customerData.contact.phoneNumber')} type="tel" label="Phone number" />
            </div>
            <div className={classes.section}>
                <Subtitle1>Address</Subtitle1>
            </div>
            <div>
                <InputField {...register(control, 'customerData.address.streetAddress')} label="Street" />
            </div>
            <div>
                <InputField {...register(control, 'customerData.address.postalCode')} label="Postal Code" />
            </div>
            <div>
                <InputField {...register(control, 'customerData.address.city')} label="City" />
            </div>
            <div>
                <SelectField {...register(control, 'customerData.address.country')} label="Country" options={countryOptions} />
            </div>
        </Form>
    );
};

const useStyles = makeStyles({
    section: {
        paddingTop: tokens.spacingVerticalXXL,
        [`@media (min-width: ${breakpoints.small})`]: {
            gridColumnStart: '1',
            gridColumnEnd: 'span 2',
        },
    },
});
