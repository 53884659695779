import { makeStaticStyles } from '@fluentui/react-components';
import { Navigate, Route } from 'react-router';
import { Routes } from 'react-router-dom';

import { CodeGenerator } from './codes/CodeGenerator';
import './common/api';
import { Layout } from './layout/Layout';
import { RedirectSessionRouting } from './redirect-sessions/RedirectSessionRouting';

export const useGlobalStyles = makeStaticStyles({
    body: {
        margin: 0,
    },
});

export const App: React.FC = () => {
    useGlobalStyles();

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="generate-code" element={<CodeGenerator />} />
                <Route path="redirect-sessions" element={<RedirectSessionRouting />} />
                <Route path="*" element={<Navigate to="redirect-sessions" replace />} />
            </Route>
        </Routes>
    );
};
