import { Field, FieldProps, makeStyles } from '@fluentui/react-components';
import { toSvg } from 'html-to-image';
import { useRef, useState } from 'react';

import { BrandedQrCode, Button, Input } from '@zastrpay/components';
import { useDebounce } from '@zastrpay/hooks';
import { tokens } from '@zastrpay/theme';

import { Page } from '../layout/Page';

export const CodeGenerator: React.FC = () => {
    const classes = useStyles();
    const qr = useRef<HTMLDivElement>(null);

    const [content, setContent] = useState('Sample content');
    const [error, setError] = useState<Pick<FieldProps, 'validationMessage' | 'validationMessageIcon' | 'validationState'>>();

    const updateContent = useDebounce((value: string) => {
        if (value) {
            setContent(value);
        } else {
            setError({ validationState: 'error', validationMessage: 'Content value can not be empty', validationMessageIcon: null });
        }
    }, 500);

    const download = async () => {
        if (qr.current) {
            const link = document.createElement('a');
            link.download = 'qr.svg';
            link.href = await toSvg(qr.current);
            link.click();
        }
    };

    return (
        <Page>
            <Field label="Content" {...error}>
                <Input defaultValue={content} onChange={(event, data) => updateContent(data.value)} />
            </Field>
            <div className={classes.container}>
                <BrandedQrCode ref={qr} content={content} />
            </div>
            <Button size="large" appearance="primary" onClick={download}>
                Download
            </Button>
        </Page>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground3,
        gap: tokens.spacingVerticalXXXL,
        borderRadius: tokens.borderRadiusLarge,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        padding: `${tokens.spacingVerticalXL} ${tokens.spacingHorizontalL} ${tokens.spacingVerticalXXXL}`,
    },
});
