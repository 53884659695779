import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';

import { breakpoints } from '@zastrpay/theme';

export type FormProps = {
    title?: string;
    action?: React.ReactNode;
};

export const Form: React.FC<PropsWithChildren<FormProps>> = ({ title, action, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {title && (
                <div className={classes.title}>
                    <Subtitle1>{title}</Subtitle1>
                    {action}
                </div>
            )}
            {children}
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '16px',
        [`@media (min-width: ${breakpoints.small})`]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    title: {
        gridColumnStart: 1,
        gridColumnEnd: 'span 2',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
