import { api, sanitize, Serialized } from '@zastrpay/common';

import { endpoints } from '../config';
import { CreatedRedirectSession, CreateRedirectSession } from './models';

const deserializeCreatedRedirectSession = (redirectSession: Serialized<CreatedRedirectSession>): CreatedRedirectSession => ({
    ...redirectSession,
    createdOn: new Date(redirectSession.createdOn),
});

export const create = (id: string, apiKey: string, data: CreateRedirectSession): Promise<CreatedRedirectSession> => {
    return api
        .put<CreatedRedirectSession>(`${endpoints.custAuthCmdh}/redirect-sessions/${id}`, sanitize(data), {
            headers: { 'x-api-key': apiKey },
        })
        .then(deserializeCreatedRedirectSession);
};
