export type ConfigMessage = {
    type: string;
    config: {
        token: string;
    };
};

export type Config = {
    token: string;
    redirectUrl: string;
};

export type MessageType = 'window-loaded' | 'window-configured' | 'configure';

export type Payload = {
    type: MessageType;
};

export type WindowLoadedPayload = Payload & {
    type: 'window-loaded';
};

export type WindowConfiguredPayload = Payload & {
    type: 'window-configured';
};

export type ConfigurePayload = Payload & {
    type: 'configure';
    config: Config;
};

export type ConsumerPayload = ConfigurePayload;

export type ClientPayload = WindowLoadedPayload | WindowConfiguredPayload;

export class Payloads {
    static windowLoaded = (): WindowLoadedPayload => {
        return { type: 'window-loaded' };
    };
    static windowConfigured = (): WindowConfiguredPayload => {
        return { type: 'window-configured' };
    };
    static configure = (config: Config): ConfigurePayload => {
        return { type: 'configure', config };
    };
}

export const redirect = (config: Config): void => {
    window.location.href = config.redirectUrl;
};
