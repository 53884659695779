import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { useFormContext } from 'react-hook-form';

import { register } from '@zastrpay/common';
import { InputField } from '@zastrpay/components';
import { breakpoints, tokens } from '@zastrpay/theme';

import { Form } from './Form';
import { CreateRedirectSession } from './models';

export const RedirectDataForm: React.FC = () => {
    const classes = useStyles();
    const { control } = useFormContext<CreateRedirectSession>();

    return (
        <Form>
            <div className={classes.section}>
                <Subtitle1 block>Redirect Data</Subtitle1>
            </div>
            <div>
                <InputField
                    {...register(control, 'redirectData.successUrl', { required: 'Success url is required' })}
                    label="Success url"
                    type="url"
                />
            </div>
            <div>
                <InputField
                    {...register(control, 'redirectData.failureUrl', { required: 'Failure url is required' })}
                    label="Failure url"
                    type="url"
                />
            </div>
            <div>
                <InputField
                    {...register(control, 'redirectData.abortUrl', { required: 'Abort url is required' })}
                    label="Abort url"
                    type="url"
                />
            </div>
            <div>
                <InputField
                    {...register(control, 'redirectData.qrCodeSuccessUrl', { required: 'Qr Code Success url is required' })}
                    label="Qr Code Success url"
                    type="url"
                />
            </div>
        </Form>
    );
};

const useStyles = makeStyles({
    section: {
        paddingTop: tokens.spacingVerticalXXL,
        [`@media (min-width: ${breakpoints.small})`]: {
            gridColumnStart: '1',
            gridColumnEnd: 'span 2',
        },
    },
});
