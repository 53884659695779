import { makeStyles } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';

import { tokens } from '@zastrpay/theme';

import { pageWidth } from '../config';

export const Page: React.FC<PropsWithChildren> = (props) => {
    const classes = useStyles();

    return <section className={classes.container}>{props.children}</section>;
};

const useStyles = makeStyles({
    container: {
        maxWidth: `calc(${pageWidth}px - 2 * ${tokens.spacingHorizontalXL})`,
        width: `calc(100% - 2 * ${tokens.spacingVerticalXL})`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: `${tokens.spacingVerticalXL} ${tokens.spacingHorizontalXL}`,
        margin: '0 auto',
        gap: '32px',
    },
});
