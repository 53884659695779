import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { useNavigate } from 'react-router-dom';

import { Button } from '@zastrpay/components';

import { Page } from '../layout/Page';

export type RedirectSessionResultProps = {
    title: string;
    icon: React.ReactElement;
};

export const RedirectSessionResult: React.FC<RedirectSessionResultProps> = ({ icon, title }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <Page>
            <div className={classes.title}>
                <div className={classes.icon}>{icon}</div>
                <Subtitle1>{title}</Subtitle1>
            </div>
            <Button onClick={() => navigate('/redirect-sessions')}>New redirect session</Button>
        </Page>
    );
};

const useStyles = makeStyles({
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        padding: '16px 0',
    },
    icon: {
        fontSize: '48px',
    },
});
