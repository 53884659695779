import { api } from '@zastrpay/common';

import { additionalHeaders } from '../config';

api.apply((instance) => {
    instance.interceptors.request.use(
        async (request) => {
            request.headers.set(additionalHeaders);

            return request;
        },
        (error) => Promise.reject(error),
    );
});
